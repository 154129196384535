<template>
  <div class="">
    <div class="home-body">
      <div class="home-block">
        <div class="home-block__item item1">
          <div class="flex items-start">
            <div class="item-count">{{ liveData.kraken_sell_price }}</div>
            <i v-if="+diffValue < 0" class="icon-arrow-top rotate"></i>
            <i v-else class="icon-arrow-top green"></i>
          </div>
          <div>
            <span>{{ diffValue }}</span>
            <span class="ml-2.5">{{ diffPercentValue }}%</span>
          </div>
        </div>
        <div class="home-block__item item2">
          <i class="icon-arrow-top"></i>
          <div class="item-title">{{ $t('level.highest') }}</div>
          <div class="item-count">{{ round(summaryData.highest) }}</div>
        </div>
        <div class="home-block__item item3">
          <i class="icon-arrow-top rotate"></i>
          <div class="item-title">{{ $t('level.lowest') }}</div>
          <div class="item-count">{{ round(summaryData.lowest) }}</div>
        </div>
        <div class="home-block__item item4">
          <i class="icon-today-price"></i>
          <div class="item-title">{{ $t('level.open_price') }}</div>
          <div class="item-count">{{ round(summaryData.today_open_price) }}</div>
        </div>
        <div class="home-block__item item5">
          <i class="icon-door"></i>
          <div class="item-title">{{ $t('level.close_price') }}</div>
          <div class="item-count">{{ round(summaryData.yesterday_close_price) }}</div>
        </div>
      </div>
      <div class="mt-9 flex items-center justify-around">
        <div class="hover:text-yellow cursor-pointer"
            :class="{ 'text-yellow underline text-base': activeTab.title === tab.title }"
            v-for="(tab, id) in timeTabsChart"
            @click="changeTab(tab)"
            :key="id">
        {{ tab.title }}
        </div>
      </div>
      <AppChart class="mt-2"
                :data="getCurrency"
                :preloader="loading"
                :tab="activeTab.title" />
    </div>
    <div class="home-footer flex items-center m:p-8 mb-10">
      <button class="btn m:px-9">
        <router-link to="/account">
          <i class="icon-order m:mr-3"></i>
          {{ $t('level.order') }}
        </router-link>
      </button>
      <button class="btn ml-auto btn-green px-5 py-2.5" @click="goToOrder">{{ liveData.average_buy_price }} <span class="ml-2.5">{{ $t('level.buy') }}</span></button>
      <button class="btn btn-red ml-5 px-5 py-2.5" @click="goToOrder">{{ liveData.average_sell_price }} <span class="ml-2.5">{{ $t('level.sell') }}</span></button>
    </div>
  </div>
</template>

<script>
import AppChart from '@/components/app/AppChart'
import { getUnixTime, fromUnixTime, format } from 'date-fns'

const timeTabsChart = [
  {
    title: 'Live',
    text: '',
    lable: 'live',
    difValue: 0
  },
  {
    title: '1H',
    lable: 'hour',
    text: '',
    difValue: 60 * 60,
    interval: 1
  },
  {
    title: '24H',
    lable: 'day',
    text: '',
    difValue: 24 * 60 * 60,
    interval: 100
  },
  {
    title: '1W',
    lable: 'week',
    text: '',
    difValue: 7 * 24 * 60 * 60,
    interval: 100
  },
  {
    title: '1M',
    lable: 'month',
    text: '',
    difValue: 30 * 24 * 60 * 60,
    interval: 100
  },
  {
    title: '1Y',
    lable: 'year',
    text: '',
    difValue: 12 * 30 * 24 * 60 * 60,
    interval: 100
  }
]

export default {
  name: 'Home',
  data: () => ({
    liveGraphValue: 0,
    activeTab: timeTabsChart[0],
    timeTabsChart
  }),
  components: {
    AppChart
  },
  created () {},
  computed: {
    loading () {
      return this.$store.state.dashboard.loading
    },
    currentCoin () {
      return this.$store.state.dashboard.currentCurrencyType
    },
    chartData () {
      if (this.activeTab.lable === 'live') return this.liveData
      else return this.getCurrency
    },
    getCurrency () {
      return {
        data: this.$store.state.dashboard.bufferBigData[this.activeTab.lable][this.currentCoin].data,
        lables: this.$store.state.dashboard.bufferBigData[this.activeTab.lable][this.currentCoin].lables
      }
    },
    liveData () {
      return this.$store.state.dashboard.liveData[this.currentCoin] ?? {}
    },
    summaryData () {
      return this.$store.state.dashboard.summaryData[this.currentCoin] ?? {}
    },
    diffValue () {
      return this.round(this.summaryData.current_price - this.summaryData.today_open_price)
    },
    diffPercentValue () {
      return this.round((this.summaryData.current_price - this.summaryData.today_open_price) / this.summaryData.today_open_price)
    }
  },
  watch: {
    currentCoin () {
      this.changeTab(this.activeTab)
    }
  },
  methods: {
    goToOrder () {
      this.$router.push('/order')
    },
    round (val) {
      return val ? val.toFixed(2) : 0
    },
    async changeTab (tab) {
      this.activeTab = tab
      if (tab.difValue !== 0) {
        const formatDate = 'yyyy-MM-dd H:mm:ss'
        const currencyObj = {
          api: {
            currency: this.currentCoin,
            'from-time': format(fromUnixTime(getUnixTime(new Date()) - tab.difValue), formatDate),
            'to-time': format(new Date(), formatDate),
            interval: tab.interval
          },
          store: {
            lable: tab.lable
          }
        }
        await this.$store.dispatch('currency_report', currencyObj)
      }
    }
  }
}
</script>

<style scoped>

</style>
