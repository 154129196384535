<template>
  <div class="">
    <div class="deposit-body flex flex-col sm:flex-row justify-between mt-8 pb-12 sm:pb-0">
      <div class="deposit-item order-item bg-base">
        <div class="order-item__field">
          <div class="lable">{{ $t('general.coins') }}</div>
          <AppSelect :i18n="false" :lable="currency" :options="currencyOptions" @select="selectCurrency" />
        </div>
        <div class="title-field flex mt-5">
          <div class="name">{{ $t('general.available_balance') }}:</div>
          <div class="count ml-auto text-yellow">10 USDT</div>
        </div>
        <p class="mt-6 mb-2.5 text-blue text-right fz-15">{{ $t('withdrawal.manage_adress') }}</p>
        <div class="order-item__field">
          <div class="lable">Withdrawal Address</div>
          <AppSelect :i18n="false" :lable="`USDT ${$t('withdrawal.withdrawal_adress')}`"
            :options="[`USDT ${$t('withdrawal.withdrawal_adress')}`]" opacityName="opacity-10"
            @select="selectCurrency" />
        </div>
        <div class="title-field flex mt-5">
          <div class="name">{{ $t('withdrawal.single_maximum') }}</div>
          <div class="count ml-auto text-white">10 USDT</div>
        </div>
      </div>
      <div class="deposit-item order-item bg-base">
        <div class="order-item__field">
          <div class="lable">{{ $t('general.amount') }}</div>
          <div class="value flex">0 <span class="ml-auto text-blue fz-15">{{ $t('withdrawal.withdraw_all') }}</span>
          </div>
        </div>
        <div class="order-item__field mt-4">
          <div class="lable">{{ $t('general.pin') }}</div>
          <div class="flex items-center">
            <div class="value opacity-10">{{ notPin }}</div>
            <i class="cursor-pointer ml-auto" :class="[ notEye ? 'icon-not-eye' : 'icon-eye']"
              @click="notEye = !notEye"></i>
          </div>
        </div>
        <p class="mt-6 mb-2.5 text-blue text-right fz-15">{{ $t('withdrawal.get_verification_code') }}</p>
        <div class="order-item__field">
          <div class="lable">{{ $t('withdrawal.enter_underfined_verification_code') }}</div>
          <div class="value opacity-10">*********************</div>
        </div>
        <div class="title-field flex mt-5">
          <div class="name">{{ $t('general.fee') }}</div>
          <div class="count ml-auto text-white">0 USDT</div>
        </div>
      </div>
      <div class="deposit-item order-item bg-base">
        <ul class="mb-7">
          <li class="mb-2">{{ $t('withdrawal.rule1') }}</li>
          <li class="mb-2">{{ $t('withdrawal.rule2') }}</li>
          <li class="mb-2">{{ $t('withdrawal.rule3') }}</li>
          <li class="mb-2">{{ $t('withdrawal.rule4') }}</li>
          <li class="mb-2">{{ $t('withdrawal.rule5') }}</li>
          <li class="mb-2">{{ $t('withdrawal.rule6') }}</li>
          <li>{{ $t('withdrawal.rule7') }}</li>
        </ul>
        <button class="btn btn-yellow justify-center">{{ $t('general.confirm') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import AppSelect from '@/components/ui/AppSelect'

export default {
  name: 'Withdrawl',
  data: () => ({
    currency: 'USDT',
    currencyOptions: ['USDT', 'BTC'],
    pin: '2834673265782365',
    notEye: true
  }),
  components: {
    AppSelect
  },
  computed: {
    notPin () {
      return this.notEye
        ? this.pin.replace(/./gm, '*')
        : this.pin
    }
  },
  methods: {
    selectCurrency (option) {
      this.currency = option
    }
  }
}
</script>
