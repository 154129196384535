<template>
  <div class="deposit-body flex flex-col sm:flex-row justify-between mt-8 pb-12 sm:pb-0">
    <div class="deposit-item order-item bg-base items-center sm:items-stretch gap-3 sm:gap-0 ">
      <div class="order-item__field w-full">
        <div class="lable">{{ $t('general.coins') }}</div>
        <AppSelect :lable="currency" :i18n="false" :options="currencyOptions" @select="selectCurrency" />
      </div>
      <div class="title-field flex mt-5 w-full justify-between sm:mt-5">
        <div class="name">{{ $t('general.available_balance') }}:</div>
        <div class="count sm:ml-auto text-white">{{ balanceFormat }} USDT</div>
      </div>
      <div class="order-item__field sm:mt-auto w-full">
        <!-- <div class="lable">Deposite</div> -->
        <!-- <div class="value">Amount</div> -->
        <div class="lable">{{ $t('general.deposite') }}</div>
        <input class="w-full value bg-gray border-gray placeholder-gray-light text-white focus:outline-none"
          id="deposite" type="number" min="0"
          :oninput="this.deposite = !!this.deposite && Math.abs(this.deposite) >= 0 ? Math.abs(this.deposite) : null"
          :placeholder="$t('deposit.choouse_amount')" required v-model.number="deposite">
      </div>
      <p v-if="errorMsg" class="text-red mt-4">{{ errorMsg }}</p>
    </div>
    <div class="deposit-item order-item bg-base">
      <div class="order-item__field">
        <div class="lable">{{ $t('deposit.deposit_adress') }}</div>
        <div class="value flex">{{ address }}
          <i v-tooltip.top-start="{content: $t('deposit.copied'), trigger: 'manual', show: show, classes: 'text-white'}"
            v-if="address" @click="copyAddress" class="icon-list ml-auto cursor-pointer"></i>
        </div>
      </div>
      <div class="deposit-item__img" v-if="qrURL">
        <img :src="qrURL" alt="" class="qr-img">
      </div>
    </div>
    <div class="deposit-item order-item bg-base">
      <ul>
        <li>{{ $t('deposit.rule1') }}</li>
        <li>{{ $t('deposit.rule2') }}</li>
        <li>{{ $t('deposit.rule3') }}</li>
      </ul>
      <button @click="confirm" class="btn btn-yellow justify-center">{{ $t('general.confirm') }}</button>
    </div>
  </div>
</template>

<script>
import AppSelect from '@/components/ui/AppSelect'

export default {
  name: 'Deposit',
  data: () => ({
    currency: 'USDT',
    currencyOptions: ['USDT', 'BTC'],
    deposite: 0,
    address: '',
    qrURL: '',
    show: false,
    errorMsg: ''
  }),
  components: {
    AppSelect
  },
  async created () {
    await this.$store.dispatch('getUserInfo')
  },
  computed: {
    balanceFormat () {
      return Number((this.$store.state.auth.user.balance || 0).toFixed(3))
    }
  },
  methods: {
    copyAddress () {
      navigator.clipboard.writeText(this.address).then(() => {
        this.show = true
        setTimeout(() => { this.show = false }, 1000)
      })
    },

    confirm () {
      this.errorMsg = ''
      this.$store.dispatch('deposit_request', { amount: this.deposite, currency_type: this.currency })
        .then(response => {
          if (response.data.error) {
            this.errorMsg = response.data.error
            return
          }
          this.address = response.data.result.address
          this.qrURL = response.data.result.qrcode_url
        })
        .catch(error => {
          console.log(error.response.data)
        })
    },

    selectCurrency (option) {
      this.currency = option
    }
  }
}
</script>

<style scoped>
.qr-img {
  border-radius: 15px;
}

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}
</style>
