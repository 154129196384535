<template>
  <div class="home-chart relative">
    <div v-if="!checkAvailabilityData" class="absolute w-full h-full bg-gray bg-opacity-75 flex justify-center items-center content-center text-3xl">
      {{ $props.preloader ? $t('general.loading') : $t(!checkAvailabilityData && 'general.no_data') }}
    </div>
    <canvas ref="chart" id="chart"></canvas>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

export default {
  name: 'Chart',
  props: {
    data: {
      type: Object,
      required: true
    },
    tab: {
      type: String
    },
    preloader: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      chart: undefined
    }
  },
  mounted () {
    const ctx = this.$refs.chart.getContext('2d')
    const gradient = ctx.createLinearGradient(0, 0, 0, 400)
    gradient.addColorStop(0, 'rgba(237, 152, 59, 0.3)')
    gradient.addColorStop(1, 'rgba(237, 152, 59, 0)')

    this.chart = new Chart(this.$refs.chart, {
      type: 'line',
      data: {
        labels: [], // Array.from({ length: 100 }, (_, i) => i + 1),
        datasets: [{
          label: '',
          data: [],
          lineTension: 0,
          backgroundColor: gradient,
          borderColor: '#ED983B',
          borderWidth: 1,
          fill: true,
          tension: 0.3
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          point: {
            radius: 0
          }
        },
        plugins: {
          legend: {
            display: false,
            tooltips: {
              callbacks: {
                label: (tooltipItem) => tooltipItem.yLabel
              }
            }
          }
        },
        scales: {
          x: {
            display: false,
            position: 'top',
            grid: {
              drawBorder: true,
              color: 'rgba(255, 255, 255, 0.1)'
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 8
            }
          },
          y: {
            position: 'right',
            grid: {
              drawBorder: true,
              color: 'rgba(255, 255, 255, 0.1)'
            }
          }
        }
      }
    })
  },
  computed: {
    checkAvailabilityData () {
      return this.$props.data.data.length !== 0
    }
  },
  watch: {
    data (newVal) {
      this.chart.data.labels = newVal.lables
      this.chart.data.datasets[0].data = newVal.data
      this.chart.update()
    }
  }
}
</script>
