<template>
  <div :class="[currentPage === 'Level' ? 'home' : 'deposit' ]">
    <TopBar @tab="changeTab" @tab-history="changeHistoryTab"/>
    <keep-alive>
      <component :is="currentPage" :tab="currentTab"/>
    </keep-alive>
  </div>
</template>

<script>
import TopBar from '@/components/app/TopBar.vue'
import Level from './Level.vue'
import History from './History.vue'
import Deposit from './Deposit.vue'
import Withdrawl from './Withdrawl.vue'

export default {
  name: 'Home',
  data: () => ({
    tabActive: 'Level',
    currentTab: ''
  }),
  components: {
    TopBar,
    Level,
    History,
    Deposit,
    Withdrawl
  },
  async created () {
    await this.$store.dispatch('getUserInfo')
  },
  computed: {
    currentPage () {
      return this.tabActive
    }
  },
  methods: {
    changeTab (tab) {
      this.tabActive = tab
      this.currentTab = tab
    },
    changeHistoryTab (tab) {
      this.tabActive = tab
    }
  }

}
</script>
