<template>

  <div class="mt-8 flex flex-col-reverse px-4 sm:flex-row items-center sm:items-stretch gap-2 sm:gap-0"
    v-if="tabActive !== 'Level'">

    <div class="deposit-top__btn flex justify-center w-full sm:w-auto">
      <button class="btn flex justify-start sm:justify-center"><i class="icon-arrow mr-3"></i>{{ $t('general.back') }}</button>

      <button @click="handleTabs('Deposit')" :class="{ 'btn-yellow': tabHistory === 'Deposit' }"
        class="btn ml-auto sm:ml-16 px-6 justify-center" v-if="tabActive === 'Deposit'">{{ $t('topbar.deposit') }}</button>
      <button @click="handleTabs('Withdrawl')" :class="{ 'btn-yellow': tabHistory === 'Withdrawl' }"
        class="btn ml-auto sm:ml-16 px-6 justify-center" v-else>{{ $t('topbar.withdrawal') }}</button>
      <button @click="handleLeftTabs('History')" :class="{ 'btn-yellow': tabHistory === 'History' }"
        class="btn ml-4 px-6 justify-center">{{ $t('topbar.history') }}</button>
    </div>
    <div class="deposit-top__tabs tabs-btn flex sm:ml-auto w-full sm:w-auto">
      <div class="tab-item" v-for="(tab, id) in tabs" :key="id" :class="{ 'active': tabActive === tab.name }"
        @click="handleTabs(tab.name)">{{ $t(tab.text) }}</div>
    </div>
  </div>

  <div v-else class="home-top m:py-8 m:flex items-center">
    <div class="home-top__left flex items-center">

      <button class="btn mr-10"><i class="icon-arrow mr-3"></i>{{ $t('general.back') }}</button>

      <div class="home-top__asset text-white mr-3 flex items-center justify-center">
        <AppSelect :i18n="false" :lable="getCurrentCoin" :options="getCoins" @select="selectCoin" :icon="false"><i
            class="icon-exchange mr-2.5"></i></AppSelect>
      </div>
      <div>
        <i class="icon-star mr-3 cursor-pointer"></i>
        <i class="icon-info"></i>
      </div>
    </div>
    <div class="home-top__tabs tabs-btn flex ml-auto">
      <div class="tab-item" v-for="(tab, id) in tabs" :key="id" :class="{ 'active': tabActive === tab.name }"
        @click="handleTabs(tab.name)">{{ $t(tab.text) }}</div>
    </div>
  </div>

</template>

<script>
import AppSelect from '@/components/ui/AppSelect'

export default {
  name: 'Topbar',
  components: {
    AppSelect
  },
  data: () => ({
    tabs: [
      {
        name: 'Level',
        text: 'topbar.level'
      },
      {
        name: 'Deposit',
        text: 'topbar.deposit'
      },
      {
        name: 'Withdrawl',
        text: 'topbar.withdrawal'
      }],
    tabActive: 'Level',
    tabLeftActive: 'Level',
    tabHistory: 'Deposit'
  }),
  computed: {
    getCoins () {
      return this.$store.state.dashboard.coins.map(coin => ({
        name: this.formatCrypto(coin.name),
        serverName: coin.name
      }))
    },
    getCurrentCoin () { return this.formatCrypto(this.$store.state.dashboard.currentCurrencyType) }
  },
  methods: {
    handleTabs (tab) {
      this.tabHistory = tab
      this.tabActive = tab
      this.$emit('tab', tab)
    },
    handleLeftTabs (tab) {
      this.tabHistory = tab
      this.$emit('tab-history', tab)
    },
    selectCoin (coin) {
      this.$store.dispatch('changeCoin', coin.serverName)
    },
    formatCrypto (str) {
      return `${str.split('USDT')[0]}/USDT`
    }
  }
}
</script>
